import React from 'react';
import { DATA_HOOKS } from './dataHooks';
import { Groups } from '../Groups/Groups';

export const App: React.FC = () => {
  return (
    <div data-hook={DATA_HOOKS.root}>
      <Groups />
    </div>
  );
};

App.displayName = 'App';
